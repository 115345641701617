import React from "react";
import Card from "../card";
import { FaGithub, FaLinkedin, FaXTwitter } from "react-icons/fa6";
import "./socialCard.css";

export default class SocialCard extends React.Component {
  render() {
    let comp = (
      <div className="socialRow">
        <span className="socialIcon sig">
          <a
            href={"https://www.github.com/" + this.props.githubAcc}
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub />
          </a>
        </span>
        <span className="socialIcon sil">
          <a
            href={"https://www.linkedin.com/in/" + this.props.linkedinAcc}
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        </span>
        <span className="socialIcon sit">
          <a
            href={"https://www.x.com/" + this.props.twitterAcc}
            target="_blank"
            rel="noreferrer"
          >
            <FaXTwitter />
          </a>
        </span>
      </div>
    );

    return <Card comp={comp}></Card>;
  }
}
